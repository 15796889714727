@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif !important;
}

.nav-container {
  @apply fixed bg-transparent z-40 w-20 h-full flex flex-col focus-within:w-5/12 justify-center text-gray-300 bg-gradient-to-r from-black via-black to-transparent;
}

.nav-link {
  @apply my-2 text-xs w-full focus:w-60 rounded-md justify-start items-center cursor-pointer flex focus:bg-activeIcon bg-transparent focus:outline-none py-3;
}

.nav-link2 {
  @apply my-2 text-xs rounded-md justify-start items-center cursor-pointer flex py-3;
}

::-webkit-scrollbar {
  display: none;
}

.exit-popup-buttons {
  @apply text-white  focus:bg-activeIcon bg-black text-base font-bold w-2/5 py-2 my-2 focus:outline-none;
}

/* html {
  font-size: 10px !important;
} */